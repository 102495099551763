import Index from "views/Index.js";
import Profile from "views/pages/Profile.js";
import Login from "views/pages/Login.js";
import Grades from "views/pages/Grades";
import Subjects from "views/pages/Subjects";
import Teachers from "views/pages/Teachers";
import Students from "views/pages/Students";
import ClassStudentDetails from "views/pages/ClassStudentDetails";
import ClassStudentPaymentRecords from "views/pages/ClassStudentPaymentRecords";
import ClassTeacherDetails from "views/pages/ClassTeacherDetails";
import ClassTeacherPaymentRecords from "views/pages/ClassTeacherPaymentRecords";
import AttendanceSheet from "views/pages/AttendanceSheet";
import TeacherPaymentDetails from "views/pages/TeacherPaymentDetails";
import ClassAssistants from "views/pages/ClassAssistants";
import Admin from "views/pages/Admin";
import ClassStudentList from "views/pages/ClassStudentList";
import StudentBulkRegistration from "views/pages/StudentBulkRegistration";
import MessageService from "views/pages/MessageService";
import ClassTeacherDailyPaymentRecords from "views/pages/ClassTeacherDailyPaymentRecords";
import Transactions from "views/pages/Transactions";
import LandingPage from "views/pages/LandingPage";
import BarCodeGenerate from "views/pages/BarCodeGenerate";
import StudentIdCreate from "views/pages/StudentIdCreate";

var routes = [
  {
    path: "/index",
    name: "Dashboard",
    icon: "ni ni-tv-2 text-ebis",
    component: <Index />,
    layout: "/admin",
  }, 
  {
    path: "/students",
    name: "Students",
    icon: "ni fa fa-users text-ebis",
    component: <Students />,
    layout: "/admin",
  },
  {
    path: "/bulk-registration",
    name: "Bulk Registration",
    icon: "ni fa fa-registered text-ebis",
    component: <StudentBulkRegistration/>,
    layout: "/admin",
  },
  {
    path: "/teachers",
    name: "Teachers",
    icon: "ni fa fa-graduation-cap text-ebis",
    component: <Teachers/>,
    layout: "/admin",
  },
  {
    path: "/admin",
    name: "Admin",
    icon: "ni fa fa-lock text-ebis",
    component: <Admin/>,
    layout: "/admin",
  },
  {
    path: "/class-assistants",
    name: "Class Assistants",
    icon: "ni fa fa-male text-ebis",
    component: <ClassAssistants/>,
    layout: "/admin",
  },
  {
    path: "/grades",
    name: "Grades",
    icon: "ni ni-paper-diploma text-ebis",
    component: <Grades/>,
    layout: "/admin",
  },
   {
    path: "/subjects",
    name: "Subjects",
    icon: "ni ni-book-bookmark text-ebis",
    component: <Subjects/>,
    layout: "/admin",
  },
  {
    path: "/mesage-service",
    name: "Message Service",
    icon: "ni fa fa-comment text-ebis",
    component: <MessageService/>,
    layout: "/admin",
  },
  {
    path: "/transactions",
    name: "Transactions",
    icon: "ni fa fa-area-chart text-ebis",
    component: <Transactions/>,
    layout: "/admin",
  },
  {
    path: "/studentId",
    name: "Student Id",
    icon: "ni fa-solid fa-barcode text-ebis",
    component: <StudentIdCreate />,
    layout: "/admin",
  }, 
  {
    path: "/barcode",
    name: "Barcode",
    icon: "ni fa-solid fa-barcode text-ebis",
    component: <BarCodeGenerate />,
    layout: "/admin",
  }, 
  {
    path: "/user-profile",
    name: "User Profile",
    icon: "ni ni-single-02 text-yellow",
    component: <Profile />,
    layout: "/admin",
  },
  {
    path: "/login",
    name: "Login",
    icon: "ni ni-key-25 text-info",
    component: <Login />,
    layout: "/auth",
  },
  {
    path: "/students/:id",
    name: "Entrolled Classes",
    icon: "ni ni-bus-front-12 text-orange",
    component: <ClassStudentDetails />,
    layout: "/admin",
  },
  {
    path: "/teachers/:id",
    name: "Teacher Class Details",
    icon: "ni ni-bus-front-12 text-orange",
    component: <ClassTeacherDetails />,
    layout: "/admin",
  },
  {
    path: "/students/payment-records/:id",
    name: "Payment Records",
    icon: "ni ni-bus-front-12 text-orange",
    component: <ClassStudentPaymentRecords />,
    layout: "/admin",
  },
  {
    path: "/teachers/payment-records/:teacherClassId/:month",
    name: "Class Teacher Payment Records",
    icon: "ni ni-bus-front-12 text-orange",
    component: <ClassTeacherPaymentRecords />,
    layout: "/admin",
  },
  {
    path: "/teachers/daily-payment-records/:teacherClassId/:date",
    name: "Class Teacher Daily Payment Records",
    icon: "ni ni-bus-front-12 text-orange",
    component: <ClassTeacherDailyPaymentRecords />,
    layout: "/admin",
  },
  {
    path: "/teachers/attendance-records/:teacherClassId/:date",
    name: "Class Teacher Payment Records",
    icon: "ni ni-bus-front-12 text-orange",
    component: <AttendanceSheet />,
    layout: "/admin",
  },
  {
    path: "/teachers/payments/:teacherId/:full_name",
    name: "Class Teacher Payment Records",
    icon: "ni ni-bus-front-12 text-orange",
    component: <TeacherPaymentDetails />,
    layout: "/admin",
  },
  {
    path: "/teachers/classStudents/:classTeacherId",
    name: "Class Teacher Students",
    icon: "ni ni-bus-front-12 text-orange",
    component: <ClassStudentList />,
    layout: "/admin",
  },
  {
    path: "*",
    name: "Dashboard",
    icon: "ni ni-tv-2 text-ebis",
    component: <LandingPage />,
  }, 
];

export default routes;
